.mobile-view-main-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(248, 248, 248);
}

.div-2 {
  padding: 2% 8% 0 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 100%;
}

.div-2-span-1 {
  color: var(--Dark, #222731);

  /* Mini Header */

  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 10px */
}


.div-2-span-2 {
  color: var(--Dark, #222731);

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-top: 8%; */


}


.div-2-span-3 {
  color: var(--Dark, #222731);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* margin-top: 2%; */
  text-align: center;
  /* 153.846% */
}



.div-2-span-4 {
  color: var(--Dark, #222731);

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-top: 7%; */
  display: flex;
  width: 100%;
}

.div-2-div-1 {
  display: flex;
  gap: 10px;
  /* margin-top: 16px */
}

.div-2-div-1-div-1 {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 7px;
  border: 1px solid var(--Borders, #C5C5C5);
}

.div-2-div-1-div-1-span {
  color: #000;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upload-btn {
  display: flex !important;
  padding: 16px !important;
  justify-content: center !important;
  align-items: flex-start !important;
  gap: 6px !important;
  border-radius: 6px !important;
  background: var(--Dark, #222731) !important;
  color: var(--White, var(--content-text-100, #FFF)) !important;

  /* Sub Headers */
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  width: 100%;
}

.upload-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: #C5C5C5 !important;
}

.video-confirm-btn {
  display: flex !important;
  padding: 16px !important;
  justify-content: center !important;
  align-items: flex-start !important;
  gap: 6px !important;
  border-radius: 6px;
  background: #6F7684 !important;
  color: var(--White, var(--content-text-100, #FFF)) !important;

  /* Sub Headers */
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  width: 100%;
}

.div-2-span-5 {
  margin-top: 4%;
  text-align: center;
  color: var(--Dark, #222731);

  /* Mini Header */

  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

}

.div-2-span-5 a {
  /* margin-top: 25px; */
  text-align: center;
  color: var(--Dark, #222731);

  /* Mini Header */

  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.progress-model .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 8px;
  border: 1.5px solid rgba(255, 255, 255, 0.30);
  background: rgba(34, 39, 49, 0.90);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(7.5px);

}

.progress-model-div {
  display: flex;
  width: 252px;
  padding: 12px 14px 15px 14px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
}

.progress-model-div-span {
  color: var(--content-text-100, #FFF);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.upload-successful-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 24px;
}

.back-btn {
  color: var(--Dark, #222731);

  /* Sub Headers */

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.step2-div {
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  height: 97%;

}

.step1-div {
  display: flex;
  padding: 15px;
  flex-direction: column;
  background-color: #000;
  height: 100%;
}

.step3-div {
  display: flex;
  padding: 15px;
  flex-direction: column;
  height: 97%;

}

.step2-div-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-tag-span {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  border: 1px solid #64686F;
  background: #222731;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}

.selected-tag-span {
  background: var(--Dark, #222731);
}

.step2-div-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  max-width: 450px;
}

.step2-div-3-div {
  display: flex;
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin-top: 10px;
}

.mobile-stepper {
  justify-content: center !important;
  background-color: transparent !important;
}

.step-2-mobile-stepper {
  justify-content: center !important;
  background-color: transparent !important;
}

.step-2-mobile-stepper .css-26w9jf-MuiMobileStepper-dot {
  background-color: #222731;
}

.mobile-stepper .css-26w9jf-MuiMobileStepper-dot {
  background-color: #FFF;
}

.mobile-stepper.css-11syc77-MuiPaper-root-MuiMobileStepper-root {
  background-color: transparent;
}

.mobile-stepper .css-114p2tk-MuiMobileStepper-dot {
  background-color: rgb(255 255 255 / 26%);
}

/* .step1-back-arrow {
  position: absolute;
  top: 2%;
  left: 4%
} */

.step1-bottom-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}

.info-div {
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin: auto;
  width: 85%;
}

.info-text {
  color: var(--Dark, #222731);
  display: flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.info-text-1 {
  color: var(--Dark, #222731);

  /* Paragraph */
  display: flex;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
mux-player {
  height: calc(100% - 50px);
  border-radius: 7px;
}
.info-input {
  border: none !important;
  border-radius: 4px;
  background: #ECECEC;
  display: flex;
  padding: 12px 12px 12px 11px;
  align-items: center;
  align-self: stretch;
  margin-top: 12px;
}

.info-text-label {
  color: var(--Dark, #222731);

  /* Sub Headers */
  display: flex;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.info-div-1 {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}


.playerStyle {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100% - 70px);
}

.swiper-div-1 {
  display: flex !important;
  padding: 9px 10px !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 5px !important;
  border-radius: 4px !important;
  border: 1px solid var(--MD-Gray, #E9E9E9) !important;
  background: var(--content-text-100, #FFF) !important;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08) !important;
  width: 140px !important;
  height: 150px !important;
  margin-right: 10px !important;
}

.swiper-div-2 {
  display: flex !important;
  padding: 9px 10px !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 5px !important;
  border-radius: 4px !important;
  border: 1px solid var(--MD-Gray, #E9E9E9) !important;
  background: var(--content-text-100, #FFF) !important;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08) !important;
  width: 100px !important;
  height: 150px !important;
  margin-right: 10px !important;
}

.swiper-div-1 span {
  color: #222731;
  text-align: center;

  /* Mini Header */
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 10px */
  opacity: 0.7;
}

.swiper-div-2 span {
  color: #222731;
  text-align: center;

  /* Mini Header */
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 10px */
  opacity: 0.7;
}

.preview-slide {
  background-color: #000;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.preview-slide-close-btn {
  display: flex !important;
  height: 44px !important;
  padding: 16px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 6px !important;
  align-self: stretch !important;
  border-radius: 6px !important;
  background: var(--content-text-100, #FFF) !important;
  color: var(--Black, #000) !important;

  /* Sub Headers */
  font-size: 13px !important;
  font-style: normal !important;
  font-family: Inter;

  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  position: absolute !important;
  bottom: 2%;
  width: 92%;
}

.step-1-bottom-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 18%;
}



.thumbnailSwiper .swiper-button-next { 
padding: 16px;
height: 50px;
width: 40px;
right: -2px;
top: 8px;
background-color: rgba(0, 0, 0, 0.8);
}
.thumbnailSwiper .swiper-button-prev { 
padding: 16px;
height: 50px;
width: 40px;
left: -2px;
top: 8px;
background-color: rgba(0, 0, 0, 0.9);
}

.thumbnailSwiper  { 
  display: flex !important;
  --swiper-navigation-size: 16px;
  --swiper-theme-color : white
}
.thumbnailSwiper .swiper-wrapper { 
  width: calc(100% - 100px);
}

